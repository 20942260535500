import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/core/Header";
import CircleButton from "../components/core/CircleButton";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import {
  Box,
  Button,
  Collapse,
  makeStyles,
  Typography,
} from "@material-ui/core";
import HeaderActions from "../components/core/HeaderActions";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Link, useNavigate } from "react-router-dom";
import Content from "../components/core/layout/Content";
import NoIntervention from "../features/interventions/components/list/NoIntervention";
import MyInterventionsFilters from "../features/interventions/components/filters/MyInterventionsFilters";
import AppConstant from "../utils/appConstant";
import { useInterventions } from "../features/interventions/hooks/useInterventions";
import Datagrid, { ColumnDef } from "../components/core/Datagrid";
import {
  Intervention,
  InterventionFilter,
  InterventionSort,
  InterventionSortFields,
  InterventionStatus,
} from "../features/interventions/types/InterventionTypes";
import { useInterventionStatus } from "../features/interventions/hooks/useInterventionStatus";
import { useDevice } from "../hooks/useDevice";
import { useConfiguration } from "../hooks/useConfiguration";
import moment from "moment";
import { useAuth } from "../features/auth/hooks/useAuth";
import { Build } from "@material-ui/icons";
import NoFilterResult from "../components/core/NoFilterResult";

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(4),
    boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
    borderRadius: theme.spacing(0.5),
    justifyContent: "center",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "& .MuiCard-root": {
      [theme.breakpoints.up("sm")]: {
        width: AppConstant.interventionCardWidthList,
      },
    },
  },
  firstCol: {
    "&:after": {
      borderRight: `1px solid ${theme.palette.grey[400]}`,
      content: "''",
      display: "block",
      height: "50%",
    },
  },
  refLink: {
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));

const MyInterventions: React.FC = () => {
  const { t } = useTranslation();
  const configuration = useConfiguration();
  const { user } = useAuth();
  const device = useDevice();
  const navigate = useNavigate();
  const classes = useStyle();
  const [interventions, setInterventions] = useState<Intervention[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 20;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<InterventionFilter>({});
  const someFilters = Object.values(filters).some((value) => !!value);
  const [sort, setSort] = useState<InterventionSort>({ rowid: "DESC" });
  const { listIntervention } = useInterventions();
  const { getStatusDisplay } = useInterventionStatus();

  useEffect(() => {
    setLoading(true);
    listIntervention(rowsPerPage, page + 1, filters, sort)
      .then((res) => {
        setInterventions(res.results.slice(0, rowsPerPage));
        setTotal(parseInt(res.total));
      })
      .finally(() => setLoading(false));
  }, [filters, listIntervention, page, rowsPerPage, sort]);

  const datagrid = useMemo(() => {
    const onRefClick = (intervention: Intervention) => {
      if (
        parseInt(intervention.status.toString()) ===
        InterventionStatus.Registered
      ) {
        navigate("/intervention/new?id=" + intervention.id);
      } else {
        navigate("/intervention/" + intervention.id);
      }
    };

    const prepareColumns = (): ColumnDef<Intervention>[] => {
      const carColumns: ColumnDef<Intervention>[] = [
        {
          header: t("MyInterHeaderCarBrand"),
          format: (value) =>
            configuration.getCarBrandFromModel(value.carType)?.label ?? "-",
        },
        {
          header: t("MyInterHeaderCarType"),
          format: (value) => {
            const label = value.carType?.label;
            return label ? label : "-";
          },
        },
      ];
      const columns: ColumnDef<Intervention>[] = [
        {
          header: t("MyInterHeaderRef"),
          format: (value) => (
            <Typography
              variant="body2"
              className={classes.refLink}
              onClick={() => onRefClick(value)}
            >
              {value.ref}
            </Typography>
          ),
          sortOnField: InterventionSortFields.ref,
          className: classes.firstCol,
          colWidth: "auto",
        },
        {
          header: t("MyInterHeaderClientRef"),
          format: (value) => value.customerRef ?? "-",
        },
        {
          header: t("MyInterHeaderPartType"),
          format: (value) => {
            const label = value.partType?.label;
            return label ? label : "-";
          },
        },
      ];
      columns.push(...(configuration.sector === "1" ? carColumns : []));
      columns.push(
        {
          header: t("MyInterHeaderDateCreation"),
          format: (value) => moment.unix(value.dateCreat).format("L"),
          sortOnField: InterventionSortFields.datec,
        },
        {
          header: t("MyInterHeaderDateTakeCharge"),
          format: (value) =>
            value.takeCharge ? moment.unix(value.takeCharge).format("L") : "-",
        },
        {
          header: t("MyInterHeaderDateSendPropal"),
          format: (value) =>
            value.propal?.sendPropal ? value.propal?.sendPropal : "-",
        },
        {
          header: t("MyInterHeaderDateSignedPropal"),
          format: (value) =>
            value.propal?.signedPropal ? value.propal?.signedPropal : "-",
        },
        {
          header: t("MyInterHeaderState"),
          format: (value) => (
            <Typography
              style={{
                color: getStatusDisplay(value.status).textColor,
                backgroundColor: getStatusDisplay(value.status).backgroundColor,
                padding: "4px 12px",
                fontSize: 12,
                fontWeight: 500,
                lineHeight: "12px",
                textTransform: "uppercase",
                textAlign: "center",
                borderRadius: 4,
              }}
            >
              {getStatusDisplay(value.status).label}
            </Typography>
          ),
          sortOnField: InterventionSortFields.fk_statut,
        },
      );
      return columns;
    };

    return (
      <Datagrid
        rows={interventions}
        rowsPerPage={rowsPerPage}
        page={page}
        total={total}
        enablePageSelector
        onChangePage={(p) => setPage(p)}
        columnsDef={prepareColumns()}
        sort={sort}
        firstColSticky
        onSort={(sort) => setSort(sort)}
        loading={isLoading}
      />
    );
  }, [
    classes.firstCol,
    classes.refLink,
    configuration,
    getStatusDisplay,
    interventions,
    isLoading,
    navigate,
    page,
    sort,
    t,
    total,
  ]);

  return (
    <>
      <Header focus={false} title={t("MyIntervention")} titleIcon={<Build />}>
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterListIcon />
              </CircleButton>
              {user?.permissions.createIntervention && (
                <Link to="/intervention/new" style={{ textDecoration: "none" }}>
                  <CircleButton color="primary" variant="contained">
                    <AddCircleOutline />
                  </CircleButton>
                </Link>
              )}
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterListIcon />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("ToggleFiltersLabel")}
              </Button>
              {user?.permissions.createIntervention && (
                <Link to="/intervention/new" style={{ textDecoration: "none" }}>
                  <Button
                    style={{ margin: "0px 16px" }}
                    color="primary"
                    variant="contained"
                    endIcon={<AddCircleOutline />}
                  >
                    {t("NewIntervention")}
                  </Button>
                </Link>
              )}
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        <Collapse in={displayFilter}>
          <MyInterventionsFilters
            applyFilters={(filters) => setFilters(filters)}
          />
        </Collapse>
        {!isLoading && total === 0 ? (
          <>
            {someFilters ? (
              <NoFilterResult />
            ) : (
              <Box className={classes.root}>
                <NoIntervention
                  title={t("MyInterventionsEmptyTitle")}
                  desc={t("MyInterventionsEmptyDesc")}
                />
              </Box>
            )}
          </>
        ) : (
          datagrid
        )}
      </Content>
    </>
  );
};

export default MyInterventions;
