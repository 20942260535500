import { ColumnOrder } from "../../../components/core/Datagrid";
import { Contact } from "../../user/types/userTypes";

export interface Address {
  street: string;
  zipCode: string;
  city: string;
  country: string;
  countryCode: string;
  fullAddress: string;
}

export interface Company {
  id?: string;
  name?: string;
  phone?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  tva_intra?: string;
  address?: Address;
  datec?: number;
  thirdPartymainContact?: Contact;
  isLocked?: "0" | "1";
}

export interface CompanyEdit {
  id?: string;
  email?: string;
  lastname?: string;
  firstname?: string;
  phone?: string;
  password?: string;
  type?: "professional" | "particular";
  name?: string;
  siret?: string;
  vat?: string;
  language?: string;
  address?: Address;
  thirdPartymainContact?: Contact;
  isLocked?: boolean;
}

export enum CompanySortFields {
  "datec" = "datec",
  "nom" = "nom",
}

export type CompanySort = Partial<Record<CompanySortFields, ColumnOrder>>;

export enum CompanyFilterFields {
  "nom" = "nom",
  "customerCode" = "customerCode",
  "distributerCode" = "distributerCode",
  "shopCode" = "shopCode",
  "email" = "email",
  "year" = "year",
  "month" = "month",
}

export type CompanyFilter = Partial<
  Record<CompanyFilterFields, string | number>
>;
