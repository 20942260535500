import {
  Box,
  Button,
  debounce,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FilterListIcon from "@material-ui/icons/FilterList";
import FilterMonth from "../../../components/filters/FilterMonth";
import FilterYear from "../../../components/filters/FilterYear";
import { CompanyFilter } from "../../companies/types/CompaniesTypes";
import FilterGarageName from "./filters/FilterGarageName";
import FilterCustomerCode from "../../companies/components/filters/FilterCustomerCode";
import FilterShopCode from "../../companies/components/filters/FilterShopCode";
import FilterEmail from "../../companies/components/filters/FilterEmail";

const useStyle = makeStyles((theme) => ({
  root: {
    marginBottom: "2em",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& > *": {
      flex: 1,
      minWidth: 250,
      [theme.breakpoints.up("md")]: { maxWidth: 300 },
    },
    gap: theme.spacing(1),
    margin: `${theme.spacing(2)}px 0`,
  },
  actionBtn: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
}));

const GaragesFilters: React.FC<{
  applyFilters: (filters: CompanyFilter) => void;
}> = (props) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const [filters, setFilters] = useState<CompanyFilter>({});

  const debouncedApply = React.useRef(
    debounce((filters) => props.applyFilters(filters), 200),
  ).current;

  const handleChange = (filters: CompanyFilter) => {
    setFilters(filters);
    debouncedApply(filters);
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <FilterListIcon style={{ marginRight: 5 }} />
        <Typography variant="h6">{t("Filters")}</Typography>
      </Box>

      <Box className={classes.filterContainer}>
        <FilterGarageName
          value={filters.nom}
          onChange={(value) => handleChange({ ...filters, nom: value })}
        />
        <FilterCustomerCode
          value={filters.customerCode}
          onChange={(value) =>
            handleChange({ ...filters, customerCode: value })
          }
        />
        <FilterShopCode
          value={filters.shopCode}
          onChange={(value) => handleChange({ ...filters, shopCode: value })}
        />
        <FilterMonth
          label={t("FilterMonthCreation")}
          value={filters.month}
          onChange={(value) => handleChange({ ...filters, month: value })}
        />
        <FilterYear
          label={t("FilterYearCreation")}
          value={filters.year}
          onChange={(value) => handleChange({ ...filters, year: value })}
        />
        <FilterEmail
          value={filters.email}
          onChange={(value) => handleChange({ ...filters, email: value })}
        />
      </Box>
      <Box className={classes.actionBtn}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleChange({})}
        >
          {t("Reset")}
        </Button>
      </Box>
    </Box>
  );
};

export default GaragesFilters;
