import { ColumnOrder } from "../../../components/core/Datagrid";
import { Distributor } from "../../distributors/types/DistributorsTypes";
import { Garage } from "../../garages/types/GarageTypes";
import { Intervention } from "../../interventions/types/InterventionTypes";

export interface Invoice {
  id: string;
  ref: string;
  status: InvoiceStatus;
  issueDate: number;
  dueDate: number;
  amount: number;
  intervention: Intervention;
  distributor: Distributor;
  garage: Garage;
}

export enum InvoiceStatus {
  Draft = 0,
  Closed = 1,
  PayedPartial = 2,
  Unpayed = 3,
  PayementStarted = 4,
  Credit = 5,
  PayedFinalready = 6,
  Payed = 7,
}

export enum InvoiceSortFields {
  "ref" = "ref",
  "datef" = "datef",
  "total_ttc" = "total_ttc",
  "date_lim_reglement" = "date_lim_reglement",
}

export type InvoiceSort = Partial<Record<InvoiceSortFields, ColumnOrder>>;

export enum InvoiceFilterFields {
  "ref" = "ref",
  "fk_statut" = "fk_statut",
  "year" = "year",
  "month" = "month",
  "nom" = "nom",
  "customerCode" = "customerCode",
  "shopCode" = "shopCode",
  "distributerCode" = "distributerCode",
}

export type InvoiceFilter = Partial<
  Record<InvoiceFilterFields, string | number>
>;
