import React, { useState } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/core/input/CotroliaLoadingButton";
import SendIcon from "@material-ui/icons/Send";
import { GarageEdit } from "../types/GarageTypes";
import FieldGarageName from "./fields/FieldGarageName";
import ContentTwoSided, {
  BothSide,
  LeftSide,
  RightSide,
} from "../../../components/core/ContentTwoSided";
import FieldGarageClientCode from "./fields/FieldGarageClientCode";
import FieldGarageEmail from "./fields/FieldGarageEmail";
import { validateEmail, validPhoneNumber } from "../../../utils/validation";
import FieldGarageTVA from "./fields/FieldGarageTVA";
import FieldGarageStoreCode from "./fields/FieldGarageStoreCode";
import ToggleSection from "../../../components/core/ToggleSection";
import { EmojiTransportation, Person } from "@material-ui/icons";
import FieldGarageFirstname from "./fields/FieldGarageFirstname";
import FieldGarageLastname from "./fields/FieldGarageLastname";
import FieldGaragePhone from "./fields/FieldGaragePhone";

interface GarageFormErrors {
  nameError?: string;
  firstameError?: string;
  lastnameError?: string;
  phoneError?: string;
  clientCodeError?: string;
  emailError?: string;
  vatError?: string;
  storeCodeError?: string;
}

const GarageForm: React.FC<{
  garage?: GarageEdit;
  edit?: boolean;
  onChange: (garage: GarageEdit) => void;
  onSend: () => Promise<void>;
}> = (props) => {
  const classes = makeStyles((theme: Theme) => ({
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  }))();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<GarageFormErrors>({});
  const [sending, setSending] = useState(false);
  const [isSiretTvaValid, setSiretTvaValid] = useState<boolean>(
    !!props.edit && !!props.garage?.vat,
  );

  const validateForm = () => {
    const formErrors: GarageFormErrors = {
      nameError: !props.garage?.name
        ? t("RequiredFieldGarageThirdpartyNameError")
        : undefined,
      firstameError: !props.garage?.firstname
        ? t("RequiredFieldGarageFirstnameError")
        : undefined,
      lastnameError: !props.garage?.lastname
        ? t("RequiredFieldGarageLastnameError")
        : undefined,
      phoneError:
        !props.garage?.phone || !validPhoneNumber(props.garage?.phone)
          ? t("RequiredFieldPhoneError")
          : undefined,
      clientCodeError: !props.garage?.customerCode
        ? t("RequiredFieldGarageClientCodeError")
        : undefined,
      emailError:
        !props.garage?.email || !validateEmail(props.garage?.email)
          ? t("RequiredFieldEmail")
          : undefined,
      vatError:
        !props.garage?.vat || !isSiretTvaValid
          ? props.edit
            ? t("RequiredFieldGarageSiretTVAEdit")
            : t("RequiredFieldGarageSiretTVA")
          : undefined,
      storeCodeError: !props.garage?.shopCode
        ? t("RequiredFieldGarageStoreCode")
        : undefined,
    };
    setErrors(formErrors);
    return (
      !formErrors.nameError &&
      !formErrors.firstameError &&
      !formErrors.lastnameError &&
      !formErrors.phoneError &&
      !formErrors.clientCodeError &&
      !formErrors.emailError &&
      !formErrors.vatError &&
      !formErrors.storeCodeError
    );
  };

  const send = async () => {
    if (validateForm()) {
      setSending(true);
      props.onSend().finally(() => setSending(false));
    }
  };

  return (
    <>
      <div>
        <ToggleSection
          title={t("EditGarageMainContact")}
          icon={<Person />}
          description={t("EditGarageMainContactDesc")}
          defaultExpanded={true}
        >
          <ContentTwoSided>
            <BothSide>
              <FieldGarageEmail
                value={props.garage?.email}
                onChange={(value) =>
                  props.onChange({ ...props.garage, email: value })
                }
                disabled={props.edit}
                error={errors.emailError}
              />
            </BothSide>
            <LeftSide>
              <FieldGarageFirstname
                value={props.garage?.firstname}
                onChange={(value) =>
                  props.onChange({ ...props.garage, firstname: value })
                }
                error={errors.firstameError}
              />
            </LeftSide>
            <RightSide>
              <FieldGarageLastname
                value={props.garage?.lastname}
                onChange={(value) =>
                  props.onChange({ ...props.garage, lastname: value })
                }
                error={errors.lastnameError}
              />
            </RightSide>
          </ContentTwoSided>
        </ToggleSection>

        <ToggleSection
          title={t("TheGarage")}
          icon={<EmojiTransportation />}
          description={t("NewGarageDesc")}
          defaultExpanded={true}
        >
          <ContentTwoSided>
            <LeftSide>
              <FieldGarageName
                value={props.garage?.name}
                onChange={(value) =>
                  props.onChange({ ...props.garage, name: value })
                }
                error={errors.nameError}
              />
              <FieldGarageClientCode
                value={props.garage?.customerCode}
                onChange={(value) =>
                  props.onChange({ ...props.garage, customerCode: value })
                }
                error={errors.clientCodeError}
              />
            </LeftSide>
            <RightSide>
              <FieldGarageTVA
                value={props.garage?.vat}
                onChange={(value) =>
                  props.onChange({
                    ...props.garage,
                    vat: value,
                  })
                }
                onValidateTVA={setSiretTvaValid}
                error={errors.vatError}
              />
              <FieldGarageStoreCode
                value={props.garage?.shopCode}
                onChange={(value) =>
                  props.onChange({ ...props.garage, shopCode: value })
                }
                error={errors.storeCodeError}
              />
              <FieldGaragePhone
                value={props.garage?.phone}
                onChange={(value) =>
                  props.onChange({ ...props.garage, phone: value })
                }
                error={errors.phoneError}
              />
            </RightSide>
          </ContentTwoSided>
        </ToggleSection>
      </div>
      <div className={classes.btnContainer}>
        <LoadingButton
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={() => send()}
          isLoading={sending}
          loadingLabel={t("WaitingSendGarage")}
          originalLabel={t("SendGarage")}
          style={{ padding: "16px 24px", borderRadius: 6 }}
        >
          {props.edit ? t("SendGarageEdit") : t("SendGarage")}
        </LoadingButton>
      </div>
    </>
  );
};

export default GarageForm;
