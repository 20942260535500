import React from "react";
import { useTranslation } from "react-i18next";
import CotroliaFormControl from "../../../../components/core/input/CotroliaFormControl";
import CotroliaVatInput from "../../../../components/core/input/CotroliaVatInput";

const FieldDistributorTVA: React.FC<{
  error?: string;
  value?: string;
  onChange: (value: string) => void;
  onValidateTVA: (isValid: boolean) => void;
}> = (props) => {
  const { t } = useTranslation();
  return (
    <CotroliaFormControl style={{ margin: 0 }} error={!!props.error}>
      <CotroliaVatInput
        id="distributortva"
        label={t("DistributorTVA")}
        value={props.value}
        onValidate={props.onValidateTVA}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => {
          props.onChange && props.onChange(e.target.value);
        }}
        required
        error={!!props.error}
        helperText={props.error ?? ""}
      />
    </CotroliaFormControl>
  );
};

export default FieldDistributorTVA;
