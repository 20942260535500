import React, { useState } from "react";
import { Theme, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../../components/core/input/CotroliaLoadingButton";
import SendIcon from "@material-ui/icons/Send";
import ContentTwoSided, {
  BothSide,
  LeftSide,
  RightSide,
} from "../../../components/core/ContentTwoSided";
import { validateEmail, validPhoneNumber } from "../../../utils/validation";
import { DistributorEdit } from "../types/DistributorsTypes";
import FieldDistributorName from "./fields/FieldDistributorName";
import FieldDistributorPhone from "./fields/FieldDistributorPhone";
import FieldDistributorCode from "./fields/FieldDistributorCode";
import FieldDistributorEmail from "./fields/FieldDistributorEmail";
import FieldDistributorTVA from "./fields/FieldDistributorTVA";
import FieldDistributorAddress from "./fields/FieldDistributorAddress";
import ToggleSection from "../../../components/core/ToggleSection";
import { Apartment, Star } from "@material-ui/icons";
import FieldDistributorFirstname from "./fields/FieldDistributorFirstname";
import FieldDistributorLastname from "./fields/FieldDistributorLastname";
import { Address } from "../../companies/types/CompaniesTypes";

interface DistributorFormErrors {
  nameError?: string;
  firstameError?: string;
  lastnameError?: string;
  phoneError?: string;
  addressError?: string;
  distributorCodeError?: string;
  emailError?: string;
  vatError?: string;
  contactError?: string;
}

const DistributorForm: React.FC<{
  distributor: DistributorEdit;
  edit?: boolean;
  onChange: (garage: DistributorEdit) => void;
  onSend: () => Promise<void>;
}> = (props) => {
  const classes = makeStyles((theme: Theme) => ({
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      margin: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  }))();
  const { t } = useTranslation();
  const [errors, setErrors] = useState<DistributorFormErrors>({});
  const [sending, setSending] = useState(false);
  const [isSiretTvaValid, setSiretTvaValid] = useState<boolean>(
    !!props.edit && !!props.distributor?.vat,
  );

  const validateForm = () => {
    const formErrors: DistributorFormErrors = {
      nameError: !props.distributor?.name
        ? t("RequiredFieldDistributorNameError")
        : undefined,
      firstameError: !props.distributor?.firstname
        ? t("RequiredFieldDistributorFirstnameError")
        : undefined,
      lastnameError: !props.distributor?.lastname
        ? t("RequiredFieldDistributorLastnameError")
        : undefined,
      phoneError:
        !props.distributor?.phone || !validPhoneNumber(props.distributor?.phone)
          ? t("RequiredFieldPhoneError")
          : undefined,
      distributorCodeError: !props.distributor?.distributerCode
        ? t("RequiredFieldDistributorCodeError")
        : undefined,
      emailError:
        !props.distributor?.email || !validateEmail(props.distributor?.email)
          ? t("RequiredFieldEmail")
          : undefined,
      vatError:
        !props.distributor?.vat || !isSiretTvaValid
          ? t("RequiredFieldDistributorSiretTVA")
          : undefined,
      // Valid address = known address with fulladdress + city + zipcode OR unkown address with street + city + zipCode + countryCode
      addressError: !validateAddress(props.distributor?.address)
        ? t("RequiredFieldAddressError")
        : undefined,
    };
    setErrors(formErrors);
    return (
      !formErrors.nameError &&
      !formErrors.phoneError &&
      !formErrors.addressError &&
      !formErrors.distributorCodeError &&
      !formErrors.emailError &&
      !formErrors.vatError &&
      !formErrors.contactError
    );
  };

  const validateAddress = (address?: Address) => {
    const isValid =
      address &&
      !!address.street &&
      !!address.city &&
      !!address.zipCode &&
      !!address.countryCode;

    return isValid;
  };

  const send = async () => {
    if (validateForm()) {
      setSending(true);
      props.onSend().finally(() => setSending(false));
    }
  };

  return (
    <>
      <div>
        <ToggleSection
          title={t("EditDistributorMainContact")}
          icon={<Star />}
          description={t("DistributorMainContactDesc")}
          defaultExpanded={true}
        >
          <ContentTwoSided>
            <BothSide>
              <FieldDistributorEmail
                value={props.distributor?.email}
                onChange={(value) =>
                  props.onChange({ ...props.distributor, email: value })
                }
                disabled={props.edit}
                error={errors.emailError}
              />
            </BothSide>
            <LeftSide>
              <FieldDistributorFirstname
                value={props.distributor?.firstname}
                onChange={(value) =>
                  props.onChange({ ...props.distributor, firstname: value })
                }
                error={errors.firstameError}
              />
            </LeftSide>
            <RightSide>
              <FieldDistributorLastname
                value={props.distributor?.lastname}
                onChange={(value) =>
                  props.onChange({ ...props.distributor, lastname: value })
                }
                error={errors.lastnameError}
              />
            </RightSide>
          </ContentTwoSided>
        </ToggleSection>

        <ToggleSection
          title={t("TheDistributor")}
          icon={<Apartment />}
          description={t("NewDistributorDesc")}
          defaultExpanded={true}
        >
          <ContentTwoSided>
            <LeftSide>
              <FieldDistributorName
                value={props.distributor?.name}
                onChange={(value) =>
                  props.onChange({ ...props.distributor, name: value })
                }
                error={errors.nameError}
              />
              <FieldDistributorPhone
                value={props.distributor?.phone}
                onChange={(value) =>
                  props.onChange({ ...props.distributor, phone: value })
                }
                error={errors.phoneError}
              />
              <FieldDistributorCode
                value={props.distributor?.distributerCode}
                onChange={(value) =>
                  props.onChange({
                    ...props.distributor,
                    distributerCode: value,
                  })
                }
                error={errors.distributorCodeError}
              />
            </LeftSide>
            <RightSide>
              <FieldDistributorTVA
                value={props.distributor?.vat}
                onChange={(value) =>
                  props.onChange({
                    ...props.distributor,
                    vat: value,
                  })
                }
                onValidateTVA={setSiretTvaValid}
                error={errors.vatError}
              />
              <FieldDistributorAddress
                value={props.distributor?.address}
                onChange={(value) =>
                  props.onChange({ ...props.distributor, address: value })
                }
                error={errors.addressError}
              />
            </RightSide>
          </ContentTwoSided>
        </ToggleSection>
      </div>
      <div className={classes.btnContainer}>
        <LoadingButton
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          onClick={() => send()}
          isLoading={sending}
          loadingLabel={t("WaitingSendDistributor")}
          originalLabel={t("SendDistributor")}
          style={{ padding: "16px 24px", borderRadius: 6 }}
        >
          {props.edit ? t("SendDistributorEdit") : t("SendDistributor")}
        </LoadingButton>
      </div>
    </>
  );
};

export default DistributorForm;
